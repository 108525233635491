import * as React from "react";

import DemoPage from "../components/demo/demo";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";

const Demo = () => {
  return (
    <>
      <Seo
        title="Demo"
        description="Découvrez les solutions Mereos"
      />
      <Layout>
        <DemoPage />
      </Layout>
    </>
  );
};

export default Demo;
